import { StoplightProject, DevPortalProvider } from '@stoplight/elements-dev-portal';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '@stoplight/elements-dev-portal/styles.min.css';
import Navbar from './components/Navbar.js';

const App = () => {
  const basePath = ''

  return (
    <DevPortalProvider>
      <BrowserRouter basename={basePath}>
        <Navbar basePath={basePath}/>
        <Switch>
          <Route exact path='/' >
            <Redirect to={`/doc-guides`} />
          </Route>
          <Route path={`/doc-guides`} >
            <StoplightProject
              projectId="cHJqOjE1NzY5MQ"
              basePath={`${basePath}/doc-guides`}
              router="history"
              collapseTableOfContents="true"
              hideMocking="true"
            />
          </Route>
          <Route path={`/api-reference`} >
            <StoplightProject
              projectId="cHJqOjE1NzY5Mw"
              basePath={`${basePath}/api-reference`}
              router="history"
              collapseTableOfContents="true"
              hideMocking="true"
            />
          </Route>
        </Switch>
      </BrowserRouter>
    </DevPortalProvider>
  );
}

export default App;

import { NavLink, Link, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { Search } from './Search';
import { useEffect } from 'react';

const Navbar = ({ basePath }) => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const [baseSlug, setBaseSlug] = useState('');

    let location = useLocation();

    useEffect(() => {
        setBaseSlug(location.pathname.split('/').slice(1, 2).toString())
    }, [location.pathname, basePath])

    return (
        <header>
            <nav className="navbar">
                <div className='navbar-icons'>
                    <Link to={`/doc-guides`} className="navbar-icons_brand">
                        <img alt="unlimint-logo" src="https://unlimint.com/cdn/assets/static/images/logos/unlimit/main.svg"></img>
                    </Link>
                    <button onClick={() => { setMobileMenu(!mobileMenu) }} className={`navbar-icons_burger ${mobileMenu && 'expanded'}`}></button>
                </div>
                {mobileMenu && <ul className="nav-list_mobile">
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`/doc-guides`}>Integration Guides</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`/api-reference`}>API Reference</NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://documenter.getpostman.com/view/10451813/SzKSTzVu" target="_blank" rel="noreferrer">Postman Collection</a>
                    </li>
                    <li className="nav-item">
                        <a className="navbar-external-link" href="https://unlimint.com/ma/" target="_blank" rel="noreferrer">Sign In</a>
                    </li>
                </ul>}
                <ul className="nav-list_desktop">
                    <li className="nav-item search">
                        {baseSlug === `doc-guides` && <Search projectIds={['cHJqOjE1NzY5MQ']} baseSlug={baseSlug} basePath={basePath} />}
                        {baseSlug === `api-reference` && <Search projectIds={['cHJqOjE1NzY5Mw']} baseSlug={baseSlug} basePath={basePath} />}
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`/doc-guides`} onClick={() => setBaseSlug('doc-guides')}>Integration Guides</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to={`/api-reference`} onClick={() => setBaseSlug('api-reference')}>API Reference</NavLink>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://documenter.getpostman.com/view/10451813/SzKSTzVu" target="_blank" rel="noreferrer">Postman Collection</a>
                    </li>
                    <li className="nav-item">
                        <a className="navbar-external-link" href="https://unlimint.com/ma/" target="_blank" rel="noreferrer">Sign In</a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Navbar
